import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { propEq } from 'ramda'
import styled from 'styled-components'

const StyledDescription = styled.div`
    h3 {
        text-transform: uppercase;
        font-size: 0.875rem;
        letter-spacing: 0.35em;
        margin-bottom: 1rem;
    }
`

const Description = ({ activeCategory }) => {
    const data = useStaticQuery(graphql`
        {
            prismic {
                allPortfolios {
                    edges {
                        node {
                            categories {
                                category
                                category_description
                            }
                        }
                    }
                }
            }
        }
    `)

    const { categories } = data.prismic.allPortfolios.edges[0].node

    const category = categories.find(propEq('category', activeCategory))

    return (
        <StyledDescription>
            <RichText render={category.category_description} />
        </StyledDescription>
    )
}

export default Description
