import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Masonry from 'react-masonry-css'
import Lightbox from 'react-spring-lightbox'
import * as R from 'ramda'
import styled from 'styled-components'

const StyledMasonry = styled(Masonry)`
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;

    .masonry-grid_column {
        padding-left: 30px; /* gutter size */
        background-clip: padding-box;

        /* Style your items */
        > div { /* change div to reference your elements you put in <Masonry> */
            margin-bottom: 30px;
        }
    }
`

const Gallery = ({ activeCategory, showAll }) => {
    const [isLightboxOpen, setLightboxOpen] = useState(false)
    const [currentImageIndex, setCurrentIndex] = useState(0)

    const data = useStaticQuery(graphql`
    {
      prismic {
        allPortfolios {
          edges {
            node {
              photos {
                photo
                category
              }
            }
          }
        }
      }
    }
  `)

    const { photos } = data.prismic.allPortfolios.edges[0].node

    // modify response objects
    const modifiedPhotos = photos.map((item) => {
        const { url, alt, Thumbnail } = item.photo
        return {
            src: url,
            alt,
            Thumbnail,
            category: [
                item.category,
                showAll,
            ]
        }
    })

    // latest photos are displayed on top
    const reversedPhotos = R.reverse(modifiedPhotos)

    // apply category filter
    const filteredPhotos = R.filter(R.where({ category: R.includes(activeCategory) }))(reversedPhotos)

    const gotoPrevious = () =>
        currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1)

    const gotoNext = () =>
        currentImageIndex + 1 < filteredPhotos.length &&
        setCurrentIndex(currentImageIndex + 1)

    const handleLightbox = (e, index) => {
        e.preventDefault()
        setCurrentIndex(index)
        setLightboxOpen(true)
    }

    return (
        <>
            <Lightbox
                isOpen={isLightboxOpen}
                onClose={() => setLightboxOpen(false)}
                onPrev={gotoPrevious}
                onNext={gotoNext}
                images={filteredPhotos}
                currentIndex={currentImageIndex}
                style={{ background: "rgba(255,255,255,0.8)" }}
            />
            <div className="mb-5">
                <StyledMasonry
                    breakpointCols={{
                        default: 3,
                        1200: 3,
                        992: 2,
                        768: 1,
                    }}
                    columnClassName="masonry-grid_column"
                >
                    {filteredPhotos.map((item, index) => {
                        const { src, Thumbnail, alt } = item
                        return (
                            <div key={index}>
                                <a
                                    className="d-block text-center"
                                    href={src}
                                    onClick={e => handleLightbox(e, index)}
                                >
                                    <img
                                        src={Thumbnail.url}
                                        alt={alt}
                                    />
                                </a>
                            </div>
                        )
                    })}
                </StyledMasonry>
            </div>
        </>
    )
}

export default Gallery
