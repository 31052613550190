import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

const CategoryLink = styled.a.attrs({
    className: 'nav-link',
    href: '#'
})`
    padding: 0;
    font-style: italic;

    ${props => props.active && `
        text-decoration: underline;
    `};
`

const Categories = ({ categoryList, activeCategory, setActiveCategory }) => {
    const data = useStaticQuery(graphql`
        {
            prismic {
                allPortfolios {
                    edges {
                        node {
                            title
                        }
                    }
                }
            }
        }
  `)

    const { title } = data.prismic.allPortfolios.edges[0].node

    return (
        <>
            <h3 className="hero-text mb-2">{RichText.asText(title)}</h3>
            <nav className="nav flex-column">
                {
                    categoryList.map(category => (
                        <CategoryLink
                            key={category}
                            active={activeCategory === category}
                            onClick={(e) => {
                                e.preventDefault()
                                setActiveCategory(category)
                            }}
                        >
                            {category}
                        </CategoryLink>
                    ))
                }
            </nav>
        </>
    )
}

export default Categories
