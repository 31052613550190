import React, { useState } from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout';

import {
    Categories,
    Description,
    Gallery,
} from '../components/portfolio'

import categoryList from '../config/portfolioCategories';

const showAll = categoryList[0]

const PortfolioPage = ({ location }) => {
    const [activeCategory, setActiveCategory] = useState(location?.state?.category || categoryList[0])

    return (
        <Layout subpage>
            <SEO title="Portfolio" />
            <div className="container">
                <div className="row my-5">
                    <div className="col-md-6 mb-5 mb-md-0">
                        <Categories
                            categoryList={categoryList}
                            activeCategory={activeCategory}
                            setActiveCategory={setActiveCategory}
                        />
                    </div>
                    <div className="col-md-6">
                        <Description
                            activeCategory={activeCategory}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Gallery
                            activeCategory={activeCategory}
                            showAll={showAll}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PortfolioPage
